<template>
  <div>
    <div class="e-breadcrumb">
      <span style="margin-left: -10px" @click="closeEvent">轮牌管理</span>
      > 编辑
    </div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div style="width: 750px">
          <el-form ref="form" label-width="230px" style="margin-top: 30px">
            <el-form-item size="small" label="名称:" required>
              <el-input v-model="item.plateName" placeholder="请输入名称" maxlength="64" show-word-limit></el-input>
            </el-form-item>

            <el-form-item size="small" label="角色:" required>
              <el-radio-group v-model="item.role">
                <el-radio :label="'0'">第一工位</el-radio>
                <el-radio :label="'1'">第二工位</el-radio>
                <el-radio :label="'2'">第三工位</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item size="small" label="每日洗牌:" required>
              <el-radio-group v-model="item.isDailyShuffle">
                <el-radio :label="'0'">否</el-radio>
                <el-radio :label="'1'">是</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item size="small" label="收银联动:" required>
              <el-radio-group v-model="item.isCashRegisterLinkage">
                <el-radio :label="'0'">否</el-radio>
                <el-radio :label="'1'">是</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item size="small" label="状态牌:" required>
              <el-radio-group v-model="item.isStatus">
                <el-radio :label="'0'">否</el-radio>
                <el-radio :label="'1'">是</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item size="small" label="点客翻牌:" required>
              <el-radio-group v-model="item.isFlop">
                <el-radio :label="'0'">否</el-radio>
                <el-radio :label="'1'">是</el-radio>
              </el-radio-group>
            </el-form-item>

          </el-form>
          <div style="margin-bottom: 30px;margin-top:30px;margin-left: 615PX">
            <el-button size="small" @click="closeEvent">取 消</el-button>
            <el-button size="small" type="primary" @click="saveBbiAppointment">确 定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        id: '',
        item: {},
      }
    },
    created() {
      this.id = this.$route.query.id
      this.getRoundPlate()
    },
    methods: {

      async getRoundPlate() {
        let res = await this.$get("/admin/getRoundPlate", {id: this.id})
        if (res.code == 200) {
          this.item = res.item
        }
      },

      closeEvent() {
        this.$router.push("/roundPlates/roundPlate")
      },

      async saveBbiAppointment() {

        if (!this.item.plateName) {
          this.$message.error("请填写名称");
          return
        }
        let data = {
          id: this.item.id,
          plateName: this.item.plateName,
          role: this.item.role,
          isDailyShuffle: this.item.isDailyShuffle,
          isCashRegisterLinkage: this.item.isCashRegisterLinkage,
          isStatus: this.item.isStatus,
          isFlop: this.item.isFlop,
        };

        let res = await this.$post("/admin/save_bbiRoundPlate", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$router.push("/roundPlates/roundPlate")
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>
